import TYPES from '@/types';

// Application
import SignOutService
  from '../../../../modules/authentication/application/services/sign-out-service';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SignOutDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.nav-bar.sign-out-dialog';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  signOut = async () => {
    await new SignOutService().logout('/login');
  }
}
