

















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import SignOutDialogViewModel
  from '@/vue-app/view-models/components/nav-bar/sign-out-dialog-view-model';

@Component({ name: 'SignOutDialog' })
export default class SignOutDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  sign_out_dialog_model = Vue.observable(new SignOutDialogViewModel());

  closeModal() {
    this.synced_is_open = false;
  }
}
